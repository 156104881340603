require('./bootstrap');


$(document).ready(function(){
    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("nav-open");
    });
});
